/*
refs
- https://fonts.google.com/noto/specimen/Noto+Sans+JP
- https://fonts.google.com/specimen/Roboto
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:ital,wght@0,400;1,700&display=swap');
@import 'yakuhanjp';

body {
  font-family:
    YakuHanJP,
    'Noto Sans JP',
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    Helvetica,
    Arial,
    sans-serif;
}
