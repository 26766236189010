/* @see src/_legacy/components/WithLegacy.tsx */
$LEGACY_CLASS_NAME: 'LEGACY';

// old common css
@font-face {
  font-family: 'Senobi-Gothic';
  src: url(/_fonts/senobi/Senobi-Gothic-Bold.ttf);
}

@font-face {
  font-family: 'use-SourceHanSansJP';
  src: url(/_fonts/source-han-sans/SourceHanSans-Heavy.ttf);
}

html:has(.#{$LEGACY_CLASS_NAME}) {
  visibility: hidden;
  &.wf-active {
    visibility: visible;
  }
}

body:has(.#{$LEGACY_CLASS_NAME}) {
  background-color: #dedede;
  font-family: YakuHanJP, source-han-sans-japanese, sans-serif;
}

// NOTE: where擬似クラスはcssの詳細度を一番低く設定するために使用する
// @refs: https://developer.mozilla.org/ja/docs/Web/CSS/:where

:where(.#{$LEGACY_CLASS_NAME}) {
  a {
    text-decoration: none;
    color: inherit;
  }

  input:focus,
  button:focus,
  textarea:focus {
    outline: none;
  }
}

// old reset css

html:has(:where(.#{$LEGACY_CLASS_NAME})),
body:has(:where(.#{$LEGACY_CLASS_NAME})) {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body:has(:where(.#{$LEGACY_CLASS_NAME})) {
  line-height: 1;
}

:where(.#{$LEGACY_CLASS_NAME}) {
  /*
   * html5doctor.com Reset Stylesheet
   * v1.6.1
   * Last Updated: 2010-09-17
   * Author: Richard Clark - http://richclarkdesign.com
   * Twitter: @rich_clark
   * */

  // html,
  // body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  // body {
  //   line-height: 1;
  // }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  nav ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  /* change colours to suit your needs */

  ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
  }

  /* change colours to suit your needs */

  mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title],
  dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* change border colour to suit your needs */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
  }

  input,
  select {
    vertical-align: middle;
  }

  button {
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
}
